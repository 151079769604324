import {logout, getTimeStrByType} from '@/assets/js/common.js'
import {getUserInfoForStorage} from '@/assets/js/sessionStorage.js'
import {mapState, mapMutations} from 'vuex'
import axios from 'axios'
// 权限btn判断
export const btnPrivilegeMixin = {
  computed: {
    ...mapState({
      btnPrivilegeCode: 'btnPrivilegeCode'
    })
  }
}

// 单位查询mixin
export const unitSearchMixin = {
  data () {
    return {
      unitArray: []
    }
  },
  methods: {
    async unitSearch () {
      let _params = {
        keyword: this.searchForm.unitName
      }
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/surveybreakrule/UnitQuery`,
          data: _params
        })
        if (data.code === 200) {
          this.unitArray = data.data.map(item => {
            return {
              unitName: item.unitName,
              unitId: item.unitId
            }
          })
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取企业信息失败, 请稍后重试!')
        }
      }
    },
    unitSelect (value) {
      this.searchForm.unitName = value.split('|')[0]
      this.searchForm.unitId = Number(value.split('|')[1])
    },
    clearAutoComplete () {
      this.searchForm.unitName = ''
      this.searchForm.unitId = ''
    }
  }
}

// 分页mixin
export const paginationMixin = {
  data () {
    return {
      paginationConfig: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  methods: {
    currentPageChange (currentPage) {
      this.paginationConfig.currentPage = currentPage
      this.getTotalData()
    },
    // 获取本地时间
    getCurrentWeek () {
      //返回date是一周中的某一天    
      let week = new Date().getDay()
      //一天的毫秒数    
      let millisecond = 1000 * 60 * 60 * 24
      //减去的天数    
      let minusDay = week != 0 ? week - 2 : 7
      //本周 周一
      this.searchForm.time = new Date(new Date().getTime() - (minusDay * millisecond))
    },
    getWeekTime (time) {
      // 周选择时间每次都为这周第二天，所以需要减一天得到第一天的时间
      let _beginTime = getTimeStrByType(new Date(time.getTime() - 1000 * 60 * 60 * 24), 3)
      let _endTime = getTimeStrByType(new Date(time.getTime() + 1000 * 60 * 60 * 24 * 5), 3)
      return [_beginTime, _endTime]
    }
  }
}

// 401权限判断mixin 
export const privilegeMixin = {
  methods: {
    checkPrivilege (error) {
      let result = false
      if (error.toString().includes('code 401')) {
        result = true
        logout()
      }
      return result
    }
  }
}

// 公共参数mixin
export const paramsMixin = {
  data () {
    return {
      // 通报治理状态
      noticeGovernStatus: [
        {label: '全部', value: 0},
        {label: '治理进行中', value: 1},
        // {label: '治理审核中', value: 2},
        {label: '附件待提交', value: 3},
        {label: '验收已完成', value: 4}
      ],
      // 违章类型
      alarmType: [
        {label: '全部', value: 0},
        {label: '超速驾驶', value: 100},
        {label: '疲劳驾驶', value: 200},
        {label: '夜间异动', value: 300},
        {label: '离线位移', value: 400},
        {label: '危险驾驶行为', value: 500},
        {label: '主动安全', value: 600},
        {label: '定位中断', value: 700}
      ],
      // 治理性质
      governCharacter: [
        {label: '政策类', value: 1},
        {label: '自行治理', value: 2},
        {label: '提前治理', value: 3}
      ],
      // 治理方式
      governWays: [
        {label: '申诉', value: 1},
        {label: '治理', value: 2},
        {label: '通报', value: 3}
      ],
      // 申诉状态 value <= 0 为生成 | type: 1
      appealStatus: [
        {label: '申诉未提交', value: 1, type: 1},
        {label: '申诉审核中', value: 2, type: 1},
        {label: '申诉未通过', value: 3, type: 1},
        {label: '申诉已失效', value: 4, type: 1},
        {label: '申诉已通过', value: 5, type: 1}
      ],
      // 治理状态 value <= 0 为生成 | type: 2
      // {label: '治理未处理', value: 1, type: 2},
      governStatus: [
        // {label: '治理已开单', value: 2, type: 2},
        {label: '治理中', value: 3, type: 2},
        // {label: '治理待审核', value: 4, type: 2},
        // {label: '治理未提交', value: 5, type: 2},
        // {label: '治理审核中', value: 6, type: 2},
        // {label: '治理未通过', value: 7, type: 2},
        {label: '治理已通过', value: 8, type: 2}
      ],
      // 处罚方式
      punishType: [
        {label: '在线学习', value: 1},
        {label: '通报批评', value: 2},
        {label: '停班', value: 3},
        {label: '罚款', value: 4},
        {label: '书面检查', value: 5},
        {label: '其他', value: 6}
      ]
    }
  },
  methods: {
    // 根据governWay和状态code返回对应中文
    getStatusByGovernType (governWay, status) {
      let result = '--'
      if (status <= 0) {
        result = '未处理'
        return result
      }
      if (governWay == 1) {
        // 申诉
        result = this.appealStatus.find(item => item.value == status).label
      } else if (governWay == 2) {
        // 治理
        result = this.governStatus.find(item => item.value == status).label
      }
      return result
    },
    // 根据处罚方式code array返回中文名
    getPunishByCode (punishIds) {
      let result = []
      punishIds.forEach(item => {
        this.punishType.forEach(pItem => {
          if (item == pItem.value) {
            result.push(pItem.label)
          }
        })
      })
      return result
    }
  }
}

// 违章详情 table colmuns
export const alarmTableMixin = {
  data () {
    return {
      alarmTableColmuns: [
        {
          title: '超速',
          id: 100,
          data: [],
          colmuns: [
            {type: 'index', title: '序号', width: 80, align: 'center'},
            {title: '超速起始时间', key: 'beginDate', align: 'center'},
            {title: '超速结束时间', key: 'endDate', align: 'center'},
            {title: '持续时长', key: 'continueTimeRe', align: 'center'},
            {title: '限速值(km/h)', key: 'speedLimit', align: 'center'},
            {title: '速度值(km/h)', key: 'topSpeed', align: 'center'},
            {title: '超速率(%)', key: 'overSpeedRate', align: 'center'},
            
          ]
        },
        {
          title: '疲劳驾驶',
          id: 200,
          data: [],
          colmuns: [
            {type: 'index', title: '序号', width: 80, align: 'center'},
            {title: '违章起始时间', key: 'beginAlarmDate', align: 'center'},
            {title: '违章结束时间', key: 'endDate', align: 'center'},
            {title: '持续时长', key: 'continueTimeRe', align: 'center'},
            {title: '违章开始位置', key: 'beginAlarmAddress', align: 'center'},
            {title: '违章结束位置', key: 'endAddress', align: 'center'}
          ]
        },
        {
          title: '夜间异动',
          id: 300,
          data: [],
          colmuns: [
            {type: 'index', title: '序号', width: 80, align: 'center'},
            {title: '违章起始时间', key: 'beginDate', align: 'center'},
            {title: '违章结束时间', key: 'endDate', align: 'center'},
            {title: '持续时长', key: 'continueTimeRe', align: 'center'},
            {title: '违章开始位置', key: 'beginAddress', align: 'center'},
            {title: '违章结束位置', key: 'endAddress', align: 'center'}
          ]
        },
        {
          title: '离线位移',
          id: 400,
          data: [],
          colmuns: [
            {type: 'index', title: '序号', width: 80, align: 'center'},
            {title: '违章起始时间', key: 'beginDate', align: 'center'},
            {title: '违章结束时间', key: 'endDate', align: 'center'},
            {title: '持续时长', key: 'continueTimeRe', align: 'center'},
            {title: '违章开始位置', key: 'beginAddress', align: 'center'},
            {title: '违章结束位置', key: 'endAddress', align: 'center'}
          ]
        },
        {
          title: '危险驾驶行为',
          id: 500,
          data: [],
          colmuns: [
            {type: 'index', title: '序号', width: 80, align: 'center'},
            {title: '报警类型', key: 'alarmTypeRe', align: 'center'},
            {title: '报警时间', key: 'alarmDate', align: 'center'},
            {title: '查看附件', slot: 'playVideo', align: 'center',
              render: (h, params) => {
                return h('div', [
                    h('Button', {
                        props: {
                          type: 'primary',
                          size: 'small',
                          icon:'ios-videocam'
                        },
                        style: {
                          borderRadius:'50%',
                          margin: '0 10px 0 0'
                        },
                        on: {
                          click: () => {
                            this.playVideo(params.row)
                          }
                        }
                    }),
                    h('Button', {
                        props: {
                          type: 'primary',
                          size: 'small',
                        },
                        style: {
                        },
                        on: {
                          click: () => {
                            this.downLoad(params.row)
                          }
                        }
                    }, '下载')
                ])
              }
            },
          ]
        },
        {
          title: '主动安全',
          id: 600,
          data: [],
          colmuns: [
            {type: 'index', title: '序号', width: 80, align: 'center'},
            {title: '违章起始时间', key: 'beginDate', align: 'center'},
            {title: '违章结束时间', key: 'endDate', align: 'center'},
            {title: '违章类型', key: 'alarmTypeRe', align: 'center'},
            {title: '违章开始位置', key: 'beginAddress', align: 'center'},
            {title: '违章结束位置', key: 'endAddress', align: 'center'},
            {title: '违章来源', key: 'breakRulesSourceRe', align: 'center'}
          ]
        },
        {
          title: '定位中断',
          id: 700,
          data: [],
          colmuns: [
            {type: 'index', title: '序号', width: 80, align: 'center'},
            {title: '违章起始时间', key: 'beginDate', align: 'center'},
            {title: '违章结束时间', key: 'endDate', align: 'center'},
            {title: '违章开始位置', key: 'beginAddress', align: 'center'},
            {title: '违章结束位置', key: 'endAddress', align: 'center'},
            {title: '违章来源', key: 'breakRulesSourceRe', align: 'center'}
          ]
        }
      ]
    }
  }
}

// 危险驾驶行为 视频播放mixin
export const dangerousDrivingBehavior = {
  data () {
    return {
      videoModal: false,
      videoForm: {
        title: '播放视频',
        url: ''
      },
      isVideo: true,
    }
  },
  methods: {
    playVideo (row) {
      if (!row.videoFile) {
        this.$Message.warning('暂无附件')
        return;
      }
      if (row.videoFile && !row.videoFile.includes('.mp4')) {
        this.isVideo = false;
      }
      this.videoForm.url = row.videoFile
      this.videoForm.title = `${row.vehicleNo}(${row.vehiclePlateColor})`
      this.videoModal = true
    },
    downLoad(row) {
        if (!row.videoFile) {
            this.$Message.warning('暂无附件')
            return;
        }
        if (row.videoFile && !row.videoFile.includes('.mp4')) {
            this.isVideo = false;
        }
        const a = document.createElement('a');
        a.href = row.videoFile;
        a.download = 'file';
        a.click();
    }
  }
}

// 页面跳转mixin
export const pageChangeMixin = {
  data () {
    return {
      componentName: 'default',
      isSearched: false, // 缓存页面searched状态 用于actived的自动刷新
    }
  },
  computed: {
    ...mapState({
      pageEditStatus: 'pageEditStatus'
    })
  },
  watch: {
    componentName (value) {
      let _pageStatus = JSON.parse(JSON.stringify(this.pageEditStatus))
      if (value != 'default') {
        // 则处于编辑状态
        _pageStatus[this.$options.name] = true
        this.setPageEditStatus(_pageStatus)
      } else {
        if (_pageStatus[this.$options.name]) {
          _pageStatus[this.$options.name] = false
          this.setPageEditStatus(_pageStatus)
        }
      }
    }
  },
  activated () {
    if (this.isSearched) {
      this.getTotalData()
    }
  },
  methods: {
    ...mapMutations({
      setPageEditStatus: 'setPageEditStatus'
    }),
    // 子页面跳转会父页面
    changeComponentName (backComponentName) {
      this.componentName = backComponentName
      if (backComponentName == 'default') {
        this.getTotalData()
      }
    }
  }
}

export const userInfoMixin = {
  computed: {
    userInfo () {
      return getUserInfoForStorage()
    }
  }
}